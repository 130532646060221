// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
global.toastr = require("toastr")

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
window.Rails = Rails;
require('jquery')
window.jQuery = $;
window.$ = $;
require("bootstrap")
require("@nathanvda/cocoon");
import 'stylesheets/application'
import "select2";
import "select2/dist/css/select2.css";
require("parsleyjs")
require('jquery-mask-plugin')
import "@fortawesome/fontawesome-free/css/all"
require("packs/plugins/jquery-ui.js")
window.moment= require('moment')
window.intlTelInput = require('packs/plugins/intlTelInput.min')
require('packs/plugins/bootstrap-select.min.js')
require('packs/plugins/timepicker.min.js')
require('packs/plugins/daterangepicker.min.js')
require('packs/plugins/jquery.datepair.min')
// require("packs/plugins/datatables.min")
// require('packs/plugins/select2.min.js')
require('packs/plugins/jquery.blockUI.js')
require("packs/plugins/bootstrap-datetimepicker.min")
require('packs/plugins/bootstrap-datepicker.min.js')
require("packs/plugins/datatable.yadcf")
require('packs/custom/custom_mobile_number')
require("packs/custom/crm.js")
require("packs/custom/simple_datatable.js")
require("packs/custom/ajax_datatable.js")
require('packs/custom/custom.js')
require("packs/custom/custom_ckeditor.js")
require("packs/plugins/custom_multi_select.js")
require("packs/custom/project.js")
require('toastr');
require("packs/cart.js");
import Sortable from "sortablejs";
require("packs/owl.carousel.min.js")



import "select2";
import "select2/dist/css/select2.css";

const images = require.context('../images', true)
require("datatables.net")
require('datatables.net-bs4')
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")

const dataTables = [];

document.addEventListener("turbolinks:load", () => {
  $('form').parsley();
  if (dataTables.length === 0 && $('.data-table').length !== 0) {
    $('.data-table').each((_, element) => {
      dataTables.push($(element).DataTable({
        pageLength: 25
      }));
    });
  }

  const sortingElement = document.getElementById("sortable-elem");

  // get calls only if div with id sortable-elem and onEnd function exists
  if (sortingElement && (typeof updateSortableOnEnd === "function")) {
    Sortable.create(sortingElement, {
      handle: ".sorting-handler",
      onEnd: updateSortableOnEnd,
    });
  }
});

document.addEventListener("turbolinks:before-cache", () => {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});

Crm.events.onLoaded(function() {
  // $('.phone-number').mask('(999) 999-9999')
  $('.timepicker').timepicker({timeFormat: 'h:i A'});
  $('.bootstrapdatepicker').datepicker({format: 'mm/dd/yyyy', todayHighlight: true, autoclose: true});
  var min_date_datepicker = new Date();
  min_date_datepicker.setHours(0,0,0,0);
  $('.block_past_datepicker').datetimepicker({
    minDate: min_date_datepicker,
    format: "MM/DD/YYYY",
    icons: {
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down",
      previous: "fa fa-chevron-left",
      next: "fa fa-chevron-right",
      today: "fa fa-clock-o",
      clear: "fa fa-trash-o"
    }
  })
  $('.datepicker').datetimepicker({
    format: "MM/DD/YYYY",
    icons: {
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down",
      previous: "fa fa-chevron-left",
      next: "fa fa-chevron-right",
      today: "fa fa-clock-o",
      clear: "fa fa-trash-o"
    }
  })
  $('.daterangepicker1').daterangepicker({
    drops: 'auto',
    autoUpdateInput: true,
    timePicker: false,
    locale: {
      format: 'MM/DD/YYYY'
    }
  });
  // $('.datetimepicker').on('change', function(){
  //   newDate = moment($(this).val(), "MM/DD/YYYY hh:mm A")
  //   if (newDate.isValid()) {
  //     $(this).val(newDate.format("MM/DD/YYYY hh:mm A"))
  //   } else {
  //     $(this).val(this.getAttribute("value"))
  //   }
  // })
  // TODO: added because somehow after adding `autoUpdateInput` the apply button stops working
  // $('.datepicker').on('apply.daterangepicker', function(ev, picker) {
  //   $(this).val(picker.startDate.format(picker.locale.format));
  //   $(this).trigger('change');
  // });
  // $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {
  //   debugger
  //   $(this).val(picker.startDate.format(picker.locale.format));
  //   $(this).trigger('change');
  // });
  // $('[data-datetimepair-container]').datepair({
  //   dateClass: 'datepicker',
  //   timeClass: 'timepicker',
  //   startClass: 'timestart',
  //   endClass: 'timeend'
  // });
  // $(".multi_select").select2({theme: 'bootstrap', closeOnSelect : true});
  $(".select_picker").selectpicker();
  $('form').submit(function(){
    $(this).find('.datepicker').each(function(){
      if($(this).val() != ""){
        let newDate = moment($(this).val(), 'MM/DD/YYYY').format('DD/MM/YYYY')
        $(this).val(newDate)
      }
    })
    $(this).find('.block_past_datepicker').each(function(){
      if($(this).val() != ""){
        let newDate = moment($(this).val(), 'MM/DD/YYYY').format('DD/MM/YYYY')
        $(this).val(newDate)
      }
    })
  })
})

$( document ).ajaxComplete(function() {
  // $.unblockUI();
});

Rails.start()
Turbolinks.start()
ActiveStorage.start()
